import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExpandMore } from '@mui/icons-material';
import { Autocomplete, Chip } from '@mui/material';
import cx from 'classnames';
import isNil from 'lodash/isNil';

import { FormControl, InputLabel, TextField } from 'components';
import { AppFieldWrapper } from 'layouts';

import { VALUE_APP_SELECT_ALL } from 'constants/parseValues';
import { useWindowSize } from 'hooks';

import FieldFormikWrapper from '../FieldWrapper/FieldFormikWrapper';
import FieldPlainWrapper from '../FieldWrapper/FieldPlainWrapper';
import './ComboBoxField.scss';
import { getElementAsync } from './utils';

const ComboBoxField = ({
    withoutFormikHook = false,
    name = '',
    label = '',
    value = '',
    defaultValue = null,
    options = [],
    optionValueKey = 'value',
    optionLabelKey = 'label',
    onChange = null,
    onClick = null,
    isRequired = false,
    allowMultipleSelections = false,
    wrapperClassName = '',
    maxTags = 1,
    mobileMaxTags = 1,
    disabled = false,
    fullWidth = false,
    helperText = '',
    noOptionsText = 'common.status.noOptions',
    allowCustomOpt = false,
    getOptionDisabled = () => {},
    ...props
}) => {
    const { t } = useTranslation();
    const { isMobile } = useWindowSize();

    const [isTyping, setIsTyping] = useState(false);

    const textFieldRef = useRef(null);
    const [inputValue, setInputValue] = useState('');

    const ComboBoxWrapper = withoutFormikHook
        ? FieldPlainWrapper
        : FieldFormikWrapper;

    const allOption =
        options?.find(
            (option) => option[optionValueKey] === VALUE_APP_SELECT_ALL
        ) ?? {};

    const getFieldValue = (wrapperProps) =>
        withoutFormikHook ? value : wrapperProps.value;

    const handleSelection =
        (wrapperProps) => (event, newAutoCompleteValues) => {
            const newFieldValues = (() => {
                if (allowMultipleSelections) {
                    const oldFieldValues = getFieldValue(wrapperProps) ?? [];
                    const newSelectedOption =
                        newAutoCompleteValues[
                            newAutoCompleteValues.length - 1
                        ] ?? {};
                    const isAllSelected =
                        newSelectedOption[optionValueKey] ===
                        VALUE_APP_SELECT_ALL;
                    const wasAllSelected = oldFieldValues.some(
                        (value) => value === VALUE_APP_SELECT_ALL
                    );

                    if (isAllSelected) {
                        return [allOption[optionValueKey]];
                    } else if (wasAllSelected) {
                        return newAutoCompleteValues
                            .filter(
                                (option) =>
                                    option[optionValueKey] !==
                                    VALUE_APP_SELECT_ALL
                            )
                            .map((option) => option[optionValueKey]);
                    } else {
                        return newAutoCompleteValues.map(
                            (option) => option[optionValueKey]
                        );
                    }
                } else {
                    return newAutoCompleteValues?.[optionValueKey] ?? '';
                }
            })();

            typeof onChange === 'function' && onChange(event, newFieldValues);
            !withoutFormikHook && wrapperProps.setValue(newFieldValues);
        };

    const checkIsTyping = (event) => {
        if (!event) {
            return;
        }

        switch (event?.type) {
            case 'change':
                setIsTyping(true);
                break;
            default:
                setIsTyping(false);
                break;
        }
    };

    const handleInputOnCustomOption = (wrapperProps) => (event) => {
        if (!allowCustomOpt) {
            return;
        }
        const inputValue = event?.target.value;

        inputValue && !withoutFormikHook && wrapperProps.setValue(inputValue);
    };

    const highlightDefaultAllOption = (wrapperProps) => async () => {
        const selectedValue = getFieldValue(wrapperProps)?.[0];

        if (
            !allowMultipleSelections &&
            selectedValue === VALUE_APP_SELECT_ALL
        ) {
            const autoCompleteList = await getElementAsync(() =>
                document.getElementsByClassName('MuiAutocomplete-listbox')
            ).then((element) => element[0]);
            const allOptionItem = autoCompleteList?.children?.[0];

            allOptionItem.setAttribute('aria-selected', true);
        }
    };

    const handleInputValueOnBlur = (wrapperProps) => {
        if (allowCustomOpt) {
            return;
        }
        const inputValue = textFieldRef.current.value;
        const isInputValueMatchOptions = options.find(
            (option) => option[optionLabelKey] === inputValue
        );

        if (!isInputValueMatchOptions) {
            setInputValue('');
        }
    };

    const renderInput = (params) => {
        const oldValue = params.inputProps.value;
        const isAllOptionRendered = oldValue === allOption[optionLabelKey];

        if (!allowMultipleSelections && !isAllOptionRendered && !isTyping) {
            const renderedOption = options.find(
                (option) => String(option[optionValueKey]) === oldValue
            );
            if (renderedOption) {
                params.inputProps.value = renderedOption[optionLabelKey];
            }
        }

        return (
            <TextField
                withoutFormikHook
                inputRef={textFieldRef}
                name={name}
                onClick={onClick}
                {...params}
            />
        );
    };

    const renderTags = (tags, getTagProps) => {
        const tagsNumber = tags.length;
        const responsiveMaxTags = isMobile ? mobileMaxTags : maxTags;
        return (
            <>
                {tags.slice(0, responsiveMaxTags).map((option, index) => (
                    <Chip
                        {...getTagProps({ index })}
                        key={index}
                        label={option?.[optionLabelKey]}
                        className="comboBoxChip"
                    />
                ))}

                {tagsNumber > responsiveMaxTags && (
                    <Chip label={`+${tagsNumber - responsiveMaxTags}`} />
                )}
            </>
        );
    };

    const parseValue = (wrapperProps) => {
        let adjustedValue = getFieldValue(wrapperProps);

        if (allowMultipleSelections) {
            if (!adjustedValue) {
                adjustedValue = [];
            }
            adjustedValue = adjustedValue.map((value) => {
                return options?.find(
                    (option) => option[optionValueKey] === value
                );
            });
        } else if (typeof adjustedValue === 'object') {
            adjustedValue = options?.find((option) => {
                return option[optionValueKey] === wrapperProps?.value || '';
            });
        }

        const isValueReset = adjustedValue === undefined;
        if (isValueReset) {
            setInputValue(adjustedValue ?? '');
        }

        return adjustedValue;
    };

    const parseOptionEqualToValue = (option, value) => {
        const optionValue =
            typeof option === 'object' ? option[optionValueKey] : option;
        const newValue =
            typeof value === 'object' ? value[optionValueKey] : value;

        return optionValue === newValue;
    };

    return (
        <ComboBoxWrapper name={name}>
            {(wrapperProps) => {
                return (
                    <AppFieldWrapper
                        className={cx('comboBoxField', wrapperClassName)}>
                        <InputLabel label={label} isRequired={isRequired} />
                        <FormControl
                            fullWidth={fullWidth}
                            helperText={
                                withoutFormikHook
                                    ? helperText
                                    : wrapperProps.helperText
                            }
                            error={
                                withoutFormikHook ? null : wrapperProps.error
                            }
                            variant="filled"
                            disabled={disabled}>
                            <Autocomplete
                                inputValue={inputValue}
                                freeSolo={allowCustomOpt}
                                autoComplete={false}
                                autoHighlight
                                clearOnBlur={false}
                                multiple={allowMultipleSelections}
                                options={options}
                                getOptionLabel={(option) =>
                                    typeof option === 'object'
                                        ? String(option[optionLabelKey])
                                        : String(option)
                                }
                                isOptionEqualToValue={parseOptionEqualToValue}
                                defaultValue={
                                    isNil(defaultValue?.value)
                                        ? null
                                        : defaultValue
                                }
                                value={parseValue(wrapperProps) ?? null}
                                popupIcon={<ExpandMore />}
                                onChange={handleSelection(wrapperProps)}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                    checkIsTyping(event);
                                    handleInputOnCustomOption(wrapperProps)(
                                        event
                                    );
                                }}
                                onOpen={highlightDefaultAllOption(wrapperProps)}
                                onBlur={(event) => {
                                    !withoutFormikHook &&
                                        wrapperProps?.handleBlur(event);
                                    handleInputValueOnBlur(wrapperProps);
                                }}
                                noOptionsText={t(noOptionsText)}
                                renderInput={renderInput}
                                disableCloseOnSelect={allowMultipleSelections}
                                renderTags={renderTags}
                                disabled={disabled}
                                getOptionDisabled={getOptionDisabled}
                                {...props}
                            />
                        </FormControl>
                    </AppFieldWrapper>
                );
            }}
        </ComboBoxWrapper>
    );
};

export default ComboBoxField;
